import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../home.css";
import slider1 from "./../../../assets/rfq/plane.webp";
import slider2 from "./../../../assets/rfq/machine-girl.webp";
import slider3 from "./../../../assets/rfq/chips.webp";
import slider4 from "./../../../assets/rfq/ram.webp";
import slider5 from "./../../../assets/rfq/screws.webp";
import slider6 from "./../../../assets/rfq/nuts.webp";
import slider7 from "./../../../assets/rfq/blueprints.webp";
import slider8 from "./../../../assets/rfq/electric-board.webp";
import slider9 from "./../../../assets/rfq/filters.webp";
import slider10 from "./../../../assets/rfq/engines.webp";
import { Link } from "react-router-dom";
import { IP_SEARCH_RADIO_BUTTON, baseUrlContact } from "./../../httpBaseUrl.js";
import AWN from "awesome-notifications";
import { Box, styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import {
  Button,
  Container,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

const ContainerSearch = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "15vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "4px",
  fontSize: "20px",
  width: "100%",
  height: "46px",
  outline: "none",
  border: "none",
  boxShadow: "inset 0 2px 3px rgba(50,50,50,.5)",
  paddingLeft: "1rem",
}));
const SearchButton = styled(Button)(({ theme }) => ({
  height: "46px",
  fontWeight: "400",
  maxWidth: "250px",
  backgroundColor: "#E13019",
  color: "white",
  fontSize: "1.2rem",
  transition: ".3s all",
  boxShadow: "0px 2.93902px 2.93902px rgba(0, 0, 0, 0.25);",
  "&:hover": {
    backgroundColor: "white",
    color: "#E13019 !important",
  },
  [theme.breakpoints.down("md")]: {
    margin: "auto",
    width: "100%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "3rem",
  display: "flex",
  alignItems: "center",
}));

const TextUpload = styled("p")(({ theme }) => ({
  fontSize: "1.5rem",
  color: "white",
  width: "50%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
}));

const ButtonUpload = styled("button")(({ theme }) => ({
  backgroundColor: "#ADACAC!important",
  display: "flex",
  alignItems: "center",
  marginRight: "-30px",
  textAlign: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "white!important",
  },
  [theme.breakpoints.down("md")]: {
    margin: "auto",
  },
}));

const InputSearchPartContainer = styled("form")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    // marginTop:'-10rem'
  },
}));

const LookingElectronicComponentsP = styled("p")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    color: "#E13019",
    fontWeight: "700",
    marginRight: "2rem",
    paddingRight: "2rem",
  },
}));

const Title = styled("h1")`
  transition: all 1.5s ease-in-out;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  font-weight: 700;
  @media (max-width: 1200px) {
    font-size: 35px;
  }
  @media (max-width: 990px) {
    font-size: 28px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const TitleContainer = styled("div")`
  width: 100%;
  height: 10rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  @media (max-width: 480px) {
    width: 90%;
  }
`;
const BannerImgContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  filter: brightness(35%);
  @media (max-width: 1350px) {
    min-height: 900px;
  }
`;

const BannerImg = styled("img")`
  width: 100%;
  object-fit: cover;
  @media (max-width: 1350px) {
    min-height: 900px;
  }
`;

const SectionOne = () => {
  let history = useHistory();

  const [electronic, setElectronic] = useState("");
  const [rfqInput, setRfqInput] = useState("");
  const [rfqAdress, setAdress] = useState("");
  const [rfqQuantity, setQuantity] = useState("");
  const [radioSearch, setRadioSearch] = useState("search");

  const mainContentRef = useRef(null);

  const handlePartNumberChange = (event) => {
    setRfqInput(event.target.value);
  };

  const handleChangeElectronic = (e) => {
    const { name, value } = e.target;
    setElectronic(`/web/electronics/${value}`);
  };

  const handleElectronicSearch = () => {
    history.push(electronic);
  }

  const handleQuantityChange = (event) => {
    // solo numeros positivos
    const regex = /^[0-9]*$/;
    const valQuantity = regex.test(event.target.value) ? true : false;
    if (valQuantity === true) {
      setQuantity(event.target.value);
    }
  };

  var [valueSearch, setValueSearch] = useState("");
  var [valueSearch2, setValueSearch2] = useState("");

  var url = baseUrlContact + `api/1/search?searchBy=${rfqInput}`;
  const dinamicUrl =
    IP_SEARCH_RADIO_BUTTON +
    `api/1/${radioSearch === "part-number" ? "search" : radioSearch}?searchBy=${rfqInput}`;

  function fetchTasks(e) {
    e.preventDefault();
    if (rfqInput.length === 13 || rfqInput.length === 9) {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          const data1 = data[1];
          const data5 = data[5];
          const struc1 = [];
          data1.map((e, index) => {
            struc1.push({
              ITEM_NAME: e.ITEM_NAME,
              NSN: e.NSN,
            });
          });

          data5.map((e, index) => {
            struc1[0].PART_NUMBER = e.PART_NUMBER;
          });
          localStorage.removeItem("data3");
          localStorage.setItem("data3", JSON.stringify(struc1));

          history.push("/web/rfq");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => console.log("We are having issues with the server. Try again later"));
    } else {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          localStorage.removeItem("data3");
          localStorage.setItem("data3", JSON.stringify(data[1]));
          history.push("/web/rfq");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => console.log("We are having issues with the server. Try again later"));
    }
  }

  // @dev redirect to serach view
  function redirectSearchView() {
    // history.push('/web/search/' + valueSearch)
    // history.push('/web/search/parts/' + valueSearch)
  }

  // emailEl
  let globalOptions = {
    positio: "bottom- right",
    maxNotifications: 3,
    animationDuration: 200,
    durations: 100,
  };
  let notifier = new AWN(globalOptions);
  const invalidExist = document.getElementsByClassName("invalid").length > 0;

  function redirectRfq(e) {
    // validar formulario
    if (!invalidExist && rfqInput && rfqQuantity) {
      e.preventDefault();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      //  fetchTasks();
      notifier.success("Request sent successfully", {
        durations: { success: 0 },
      });

      localStorage.removeItem("rfqInfo");
      localStorage.removeItem("tblRFQ");
      localStorage.setItem(
        "rfqInfo",
        JSON.stringify({
          rfqHomeInfo: rfqInput,
          rfqQuantity: rfqQuantity,
        })
      );
      history.push("/web/rfq");
      fetchTasks();
      // }
    } else {
      // e.preventDefault();

      notifier.alert("Review Input Fields", { durations: { alert: 0 } });
      // setTimeout(() => {
      //     window.location.reload();
      // }, 1000);
    }
  }

  function SearchF(event) {
    if (event.target.value.length === 13 || event.target.value.length === 9) {
      // setValueSearch(event.target.value)
      // setValueSearch2( "/web/search/" + event.target.value)
      setValueSearch2("/web/search/" + event.target.value + "/1");
    } else {
      // setValueSearch(event.target.value)
      // setValueSearch2( "/web/search/" + event.target.value)
      setValueSearch2("/web/search/" + event.target.value + "/1");
      // history.push('/web/search/' + event.target.value)
    }
  }

  const handlePartSearch = () => {
    history.push(valueSearch2)
  }

  //*navegacion con el teclado wai-aria
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      history.push(valueSearch2);
    }
  };

  const handleChangeRadio = (e, val) => {
    setRadioSearch(val);
    localStorage.setItem("radioSearch", JSON.stringify(val));
    const radio = localStorage.getItem("radioSearch");
  };

  useEffect(() => {
    setRadioSearch("search");
    localStorage.setItem("radioSearch", JSON.stringify("search"));
  }, []);

  // animated banner
  const [currentIndex, setCurrentIndex] = useState(0);
  const phrases = [
    "Supplying our Warfighters and Allies with Critical Spare Parts",
    "Solving Long Lead Times, Obsolescence and Shortages in the Electronic Components Industry",
    "Fasteners and Hardware",
    "Reverse Engineering and Build to Print Services",
    "Bearings, Drives and Filters",
  ];
  const bannerImgs = [
    slider1,
    slider2,
    slider3,
    slider4,
    slider5,
    slider6,
    slider7,
    slider8,
    slider9,
    slider10,
  ];

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const getVisiblePhraseIndex = (index) => {
    return Math.floor(index / 2) % phrases.length;
  };

  return (
    <main className="main">
      <Slider {...settings}>
        {bannerImgs.map((img, index) => (
          <BannerImgContainer key={index}>
            <BannerImg src={img} alt={`Banner ${index}`} />
          </BannerImgContainer>
        ))}
      </Slider>
      <ContainerSearch>
        <TitleContainer ref={mainContentRef}>
          <Title id="main-content">{phrases[getVisiblePhraseIndex(currentIndex)]}</Title>
        </TitleContainer>
        <Container sx={{ mt: 3, width: "100%" }} maxWidth="md">
          <StyledTypography sx={{ mt: 2, mb: 1 }} fontWeight={700} variant="h2" color="white">
            PART SEARCH
          </StyledTypography>
          <Grid sx={{ width: "100%" }} container spacing={2}>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledInput
                    aria-label="Search"
                    type="text"
                    onChange={SearchF}
                    sx={{ width: "100%" }}
                    placeholder="Search Part"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="type-search"
                      name="row-radio-buttons-search"
                      value={radioSearch}
                      onChange={handleChangeRadio}
                      sx={{
                        mx: "1em",
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.target.click();
                        }
                      }}
                    >
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "1.2rem",
                            color: "white",
                          },
                        }}
                        value="search"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="General Search"
                      />
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "1.2rem",
                            color: "white",
                          },
                        }}
                        value="searchNSN"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="NSN"
                      />
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "1.2rem",
                            color: "white",
                          },
                        }}
                        value="searchNiin"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="NIIN"
                      />
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "1.2rem",
                            color: "white",
                          },
                        }}
                        value="part-number"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="Part Number"
                      />
                      {/* <FormControlLabel
                        value="search"
                        control={<Radio />}
                        label="Part Number"
                      /> */}
                      <label htmlFor="search"></label>
                      <input type="radio" name="search" value="search" id="search" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <SearchButton
                sx={{ width: "100%" }}
                onClick={handlePartSearch}
              >
                SEARCH
              </SearchButton>
            </Grid>
          </Grid>
          <br/>
          <StyledTypography sx={{ mt: 3, mb: 1 }} fontWeight={700} variant="h2" color="white">
            ELECTRONIC COMPONENTS SEARCH
          </StyledTypography>
          <Grid sx={{ width: "100%" }} container spacing={2}>
            <Grid item xs={12} md={9}>
              <StyledInput
                onChange={handleChangeElectronic}
                sx={{ width: "100%" }}
                placeholder="Search by Electronic Parts"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SearchButton
                sx={{ width: "100%" }}
                onClick={handleElectronicSearch}
              >
                SEARCH
              </SearchButton>
            </Grid>
          </Grid>
          <br/>
          <StyledTypography fontSize={'19px'} fontWeight={600} align="left" variant="p" color="white">
            Have a list of items that needs quoting or do you have surplus inventory? Upload your
            list in our{" "}
            <Link to="/web/uploadrfq" style={{ color: "#E53119" }}>
              Upload RFQ
            </Link>{" "}
            page and we will get back to you with a quote
          </StyledTypography>
        </Container>
        {/* </form> */}
      </ContainerSearch>
    </main>
  );
};

export default SectionOne;
