import React from 'react'
import { useHistory } from 'react-router-dom'

import "./../rfq.css";
import slider1 from "./../../../assets/rfq/3-1.webp";
import slider2 from "./../../../assets/rfq/4-1.webp";
import slider3 from "./../../../assets/rfq/7_720.webp";

const Header = () => {
     // En tu función
     let history = useHistory();
     const rfq = () => {
         let path ="/web/uploadrfq"
         history.push(path);
     }

    

    return (
        <div>
            <div>
                <div className="sliderRfq">
                    <ul>
                        <li>
                            <img src={slider1} alt="Tractor harvesting." />
                        </li>
                        <li>
                            <img src={slider2} alt="Robotic arm controlled by tablet." />
                        </li>
                        <li>
                            <img src={slider3} alt="Warship" />
                        </li>
                    </ul>
                </div>
            </div>

            <div className='headerComponent'>
                <h1>Request a Quote</h1>
                <h2>You can submit your RFQ via online form below or</h2>
                <button onClick={rfq}>submit a list of parts here</button>
            </div>
        </div>
    )
}

export default Header