import React from 'react'
import "./../nsns.css";
import slider1 from "./../../../assets/rfq/3-1.webp";
import slider2 from "./../../../assets/rfq/4-1.webp";
import slider3 from "./../../../assets/rfq/7_720.webp";


const Header = () => (
    <div>
        <div className="sliderNSNS">
            <ul>
                <li>
                    <img src={slider1} alt="Tractor harvesting." />
                </li>
                <li>
                    <img src={slider2} alt="Robotic arm controlled by tablet." />
                </li>
                <li>
                    <img src={slider3} alt="Warship" />
                </li>
            </ul>
        </div>

        <div className='contentHeaderNsns'>
            <div className='headerNsns'>
                <h1>FSC & NSN Glossary</h1>
            </div>
        </div>
    </div>
)

export default Header