import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "../home/home.css";
import slider1 from "./../../assets/rfq/plane.webp";
import slider2 from "./../../assets/rfq/tank.webp";
import slider3 from "./../../assets/rfq/ship.webp";
import { Link } from "react-router-dom";
import { IP_SEARCH_RADIO_BUTTON, baseUrlContact } from "./../httpBaseUrl";
import { Box, styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import {
  Button,
  Container,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";


const ContainerSearch = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: '30vh',
  width: "100%",
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    alignItems: 'center'
  },
}));

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "4px",
  fontSize: "20px",
  width: "100%",
  height: "46px",
  outline: "none",
  border: "none",
  boxShadow: "inset 0 2px 3px rgba(50,50,50,.5)",
}));
const SearchButton = styled(Button)(({ theme }) => ({
  height: "46px",
  fontWeight: "400",
  maxWidth: "250px",
  backgroundColor: "#E13019",
  color: "white",
  fontSize: "1.2rem",
  transition: ".3s all",
  boxShadow: "0px 2.93902px 2.93902px rgba(0, 0, 0, 0.25);",
  "&:hover": {
    backgroundColor: "white",
    color: "#E13019 !important",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
  },
}));
const StyledParagraph = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center !important",
    width: "100%",
    fontSize: '14px',
  },
}));

const BannerImgContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  filter: brightness(35%);
  @media (max-width: 1350px) {
    min-height: 900px;
  }
`;

const BannerImg = styled("img")`
  width: 100%;
  object-fit: cover;
  @media (max-width: 1350px) {
    min-height: 900px;
  }
`;

const NsnBanner = () => {
  let history = useHistory();


  const [rfqInput, setRfqInput] = useState("");

  const [rfqQuantity, setQuantity] = useState("");
  const [radioSearch, setRadioSearch] = useState("searchNSN");


  var [valueSearch, setValueSearch] = useState("");
  var [valueSearch2, setValueSearch2] = useState("");

  var url = baseUrlContact + `api/1/search?searchBy=${rfqInput}`;
  const dinamicUrl =
    IP_SEARCH_RADIO_BUTTON + `api/1/${radioSearch === 'part-number' ? 'search' : radioSearch}?searchBy=${rfqInput}`;

  function fetchTasks() {
    if (rfqInput.length === 13 || rfqInput.length === 9) {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          const data1 = data[1];
          const data5 = data[5];
          const struc1 = [];
          data1.map((e, index) => {
            struc1.push({
              ITEM_NAME: e.ITEM_NAME,
              NSN: e.NSN,
            });
          });
          data5.map((e, index) => {
            struc1[0].PART_NUMBER = e.PART_NUMBER;
          });
          localStorage.removeItem("data3");
          localStorage.setItem("data3", JSON.stringify(struc1));

          history.push("/web/rfq");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) =>
          console.log("We are having issues with the server. Try again later")
        );
    } else {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          localStorage.removeItem("data3");
          localStorage.setItem("data3", JSON.stringify(data[1]));
          history.push("/web/rfq");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) =>
          console.log("We are having issues with the server. Try again later")
        );
    }
  }

  function SearchF(event) {
    if (event.target.value.length === 13 || event.target.value.length === 9) {
      // setValueSearch(event.target.value)
      // setValueSearch2( "/web/search/" + event.target.value)
      setValueSearch2("/web/search/" + event.target.value + "/1");
    } else {
      // setValueSearch(event.target.value)
      // setValueSearch2( "/web/search/" + event.target.value)
      setValueSearch2("/web/search/" + event.target.value + "/1");
      // history.push('/web/search/' + event.target.value)
    }
  }

  const handleSearch = () => {
    history.push(valueSearch2);
  }

  const handleChangeRadio = (e, val) => {
    setRadioSearch(val);
    localStorage.setItem("radioSearch", JSON.stringify(val));
    const radio = localStorage.getItem("radioSearch");
  };

  const isMobile = useMediaQuery('(max-width: 800px)')

  // animated banner
  const [currentIndex, setCurrentIndex] = useState(0);
  const bannerImgs = [
      slider1,
      slider2,
      slider3,
  ];

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  return (
    <main className="main ">
      <Helmet>
        <title>PartMiner NSN Search</title>
      </Helmet>
      <Slider {...settings}>
        {bannerImgs.map((img, index) => (
          <BannerImgContainer key={index}>
            <BannerImg src={img} alt={`Banner ${index}`} />
          </BannerImgContainer>
        ))}
      </Slider> 
      <ContainerSearch>
        <Container maxWidth='md' >
          <Title
            fontWeight={700}
            variant="h1"
            color="white"
            fontSize={isMobile ? 50 : 85}
            textAlign='center'
          >
            NSN SEARCH
          </Title>
          <br/>
          <StyledTypography
            fontWeight={700}
            variant="h2"
            color="#D0402B"
            fontSize={isMobile ? 20 : 36}
            textAlign='center'
            >
            Supporting our Millitary and Allies
          </StyledTypography>
          <br/><br/>
          <Grid sx={{ width: "100%" }} container spacing={2}>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledInput
                    aria-label="Search"
                    type="text"
                    onChange={SearchF}
                    sx={{ width: "100%", pl: '20px'}}
                    placeholder="Search NSN"
                  />
                </Grid>
                <Grid item xs={12} >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="type-search"
                      name="row-radio-buttons-search"
                      value={radioSearch}
                      onChange={handleChangeRadio}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.target.click();
                        }
                      }}
                    >
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "2rem",
                            color: "white",
                          },
                        }}
                        value="search"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="General Search"
                      />
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "2rem",
                            color: "white",
                          },
                        }}
                        value="searchNSN"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="NSN"
                      />
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "2rem",
                            color: "white",
                          },
                        }}
                        value="searchNiin"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="NIIN"
                      />
                      <FormControlLabel
                        tabIndex={0}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "2rem",
                            color: "white",
                          },
                        }}
                        value="part-number"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked .MuiSvgIcon-root": {
                                color: "white",
                              },
                              "& .MuiSvgIcon-root": {
                                backgroundColor: "transparent",
                                color: "white",
                                borderRadius: "50%",
                                fontSize: "2rem",
                              },
                            }}
                          />
                        }
                        label="Part Number"
                      />
                      {/* <FormControlLabel
                        value="search"
                        control={<Radio />}
                        label="Part Number"
                      /> */}
                      <label htmlFor="search"></label>
                      <input type="radio" name="search" value="search" id="search"/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <SearchButton
                sx={{ width: "100%" }}
                onClick={handleSearch}
              >
                SEARCH
              </SearchButton>
            </Grid>
          </Grid>
          <br/>
          <StyledParagraph
            fontWeight={400}
            variant="p"
            color="white"
            fontSize={16}
          >
            Have a list of items that needs quoting or do you hace a surplus inventory? Upload your list in our
            <Link
              style={{color: '#D0402B', textDecoration: 'underline'}}
              to={'/'}
            >
              Upload RFQ
            </Link>
            page and we will get back to you  with a quote
          </StyledParagraph>
        </Container>
      </ContainerSearch>
    </main>
  );
};

export default NsnBanner;