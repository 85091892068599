const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
  },
  mainPanel: {
    position: "relative",
  },
});

export default appStyle;
