import React, { useEffect, useRef, useState } from "react";
import "./../home.css";
import "./../../../assets/plugins/landing.min.css";
import "./../../../components/nsns/nsns.css";
import { Link } from "react-router-dom";
import Logo from "./../../../assets/home/logoOriginal.png";
import MenuIcon from "./../../../assets/home/MenuIcon.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  Modal,
  styled,
} from "@mui/material";
import { ExpandLess, ExpandMore, ArrowForwardIos } from "@material-ui/icons";
import axios from "axios";

const LinkTo = styled(Link)(({ theme }) => ({
  transition: ".3s all",
  "&:hover": {
    color: "#D0402B",
  },
}));

const Menu = () => {
  const [modalMenu, setmodalMenu] = useState({
    left: "100%",
    visibility: "hidden",
  });
  const [GroupInfo, setGroupInfo] = useState([]);
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);

  function ShowModalMenu(show) {
    if (show === true) {
      setmodalMenu({ left: "0%", visibility: "visible" });
    } else {
      setmodalMenu({ left: "100%", visibility: "hidden" });
    }
  }

  function GetGroups() {
    axios
      .get("https://api.part-miner.com/api/fsg/groups")
      .then(function (res) {
        setGroupInfo(res.data);
      })
      .catch((error) =>
        console.log("We are having issues with the server. Try again later")
      );
  }

  useEffect(() => {
    GetGroups();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const [expandedPanelContent, setExpandedPanelContent] = useState(null);

  const handleAccordionClick = (panelId) => {
    setExpandedPanelContent(expandedPanelContent === panelId ? null : panelId);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setExpandedPanelContent(null);
    ShowModalMenu(false);
  };

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header
        id="pass-header"
        className="header header-transparent menuHeaderAll"
      >
        <div className="header-middle sticky-header fix-top sticky-content has-center">
          <div className="container-fluid NewColorMenu">
            <div className="header-left">
              <Link to="/web/">
                <span className="logo">
                  <img src={Logo} alt="logo" width={153} height={44} />
                </span>
              </Link>
              {/* End Logo */}
              {/* <span className="divider" /> */}
            </div>
            <a href="#main-content" className="skip" onClick={() => mainContentRef.current.focus()}>
              Skip to main content
            </a>
            <div className="header-center">
              <nav className="main-nav d-lg-block d-none">
                <ul className="menu menu-active-underline">
                  <li>
                    <LinkTo
                      to="/web/home"
                      style={{color: location.pathname === '/web/home' ? '#D0402B' : 'black'}}
                    >
                      HOME
                    </LinkTo>
                  </li>
                  <li>
                    <LinkTo
                      to={null}
                      style={{color: 'black'}}
                      onClick={(e) => {
                        e.preventDefault()
                        setModalOpen(true)
                      }}
                      onKeyDown={(event) => {
                        if(event.key === "Enter"){
                          setModalOpen(true)
                        }
                      }}
                    >
                      OUR LINE CARD
                      <Modal
                        open={modalOpen}
                        onClose={() => {
                          setModalOpen(false);
                          setExpandedPanelContent(null);
                        }}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        BackdropProps={{
                          invisible: true,
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <List
                          component="div"
                          disablePadding
                          sx={{
                            width: "300px",
                            position: "fixed",
                            top: "100px",
                            backgroundColor: "white",
                            padding: "20px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="FSCHome" ref={modalRef}>
                            <div className="acordeonNsNs">
                              {GroupInfo ? (
                                GroupInfo.map((el, index) => {
                                  const panelId = `panel${index}`;
                                  return (
                                    <div key={panelId}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          cursor: "pointer",
                                          marginBottom: "10px",
                                          padding: "10px",
                                          borderRadius: "4px",
                                          backgroundColor:
                                            expandedPanelContent === panelId
                                              ? "#D0402B"
                                              : "#FFFFFF",
                                          color:
                                            expandedPanelContent === panelId
                                              ? "#FFFFFF"
                                              : "#000000",
                                        }}
                                        onClick={() =>
                                          handleAccordionClick(panelId)
                                        }
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            handleAccordionClick(panelId);
                                          }
                                        }}
                                        tabIndex="0"
                                      >
                                        {el.FSG_TITLE}
                                        <ArrowForwardIos />
                                      </Box>
                                      {expandedPanelContent === panelId && (
                                        <Box
                                          className="acordeonNsNs"
                                          sx={{
                                            width: "550px",
                                            height: "100%",
                                            position: "absolute",
                                            left: "100%",
                                            top: 0,
                                            cursor: "pointer",
                                            marginBottom: "10px",
                                            padding: "10px",
                                            borderRadius: "4px",
                                            backgroundColor: "#FFFFFF",
                                            padding: "20px",
                                            boxShadow:
                                              "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                            borderRadius: "8px",
                                            overflowY: "scroll"
                                          }}
                                          tabIndex="0"
                                        >
                                          <ul>
                                            {el.FSC_GROUP.map((el2, index2) => (
                                              <li key={`FSC ${index2}`}>
                                                <span>
                                                  <Link
                                                    onClick={() => {
                                                      setTimeout(() => {
                                                          handleCloseModal()
                                                        }, 1)
                                                    }}
                                                    onKeyDown={(event) => {
                                                      if(event.key === "Enter") {
                                                        setTimeout(() => {
                                                          handleCloseModal()
                                                        }, 1)
                                                      }
                                                    }}
                                                    to={`/web/fsgs/${el.FSG}/fsc/${el2.FSC}/1`}
                                                    tabIndex="0"
                                                  >
                                                    {el2.FSC_TITLE}
                                                  </Link>
                                                </span>
                                                <Divider
                                                  light
                                                  sx={{ marginY: "10px" }}
                                                />
                                              </li>
                                            ))}
                                          </ul>
                                        </Box>
                                      )}
                                    </div>
                                  );
                                })
                              ) : (
                                <h1>Loading...</h1>
                              )}
                            </div>
                          </div>
                        </List>
                      </Modal>
                    </LinkTo>
                  </li>
                  <li>
                    <LinkTo
                      to="/web/fsg/1"
                      style={{color: location.pathname === '/web/fsg/1' ? '#D0402B' : 'black'}}
                    >
                      NSN SEARCH
                    </LinkTo>
                  </li>
                  <li>
                    <LinkTo
                      to="/web/electronics"
                      style={{color: location.pathname === '/web/electronics' ? '#D0402B' : 'black'}}
                    >
                      ELECTRONIC COMPONENTS
                    </LinkTo>
                  </li>
                  <li>
                    <LinkTo
                      to="/web/uploadrfq"
                      style={{color: location.pathname === '/web/uploadrfq' ? '#D0402B' : 'black'}}
                    >
                      UPLOAD RFQ
                    </LinkTo>
                  </li>
                  <li>
                    <LinkTo
                      to="/web/aboutus"
                      style={{color: location.pathname === '/web/aboutus' ? '#D0402B' : 'black'}}
                    >
                      ABOUT US
                    </LinkTo>
                  </li>
                  <li className="d-xl-show submenu-container">
                    <LinkTo to="/web/contactUs">
                      <Button
                        className="btn btn-solid gra-reversed btn-purchase"
                        style={{
                          borderRadius: "1rem",
                          border: "none",
                          fontSize: "1.5rem",
                        }}
                      >
                        CONTACT US
                      </Button>
                    </LinkTo>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className="MenuMovilContent">
        <Link to="/web/">
          <span className="logo">
            <img src={Logo} alt="Logo" className="imgFirst" />
          </span>
        </Link>
        <img
          src={MenuIcon}
          alt="Menu"
          className="imgLast"
          onClick={() => {
            ShowModalMenu(true);
          }}
        />
      </div>
      {/* <div className='MenuMovilOptions'></div> */}
      {/* <div  className='MenuMovilOptions' style={"visibility:hidden"}> */}
      <div className="MenuMovilOptions" style={modalMenu}>
        <div className="Close">
          <h1
            onClick={() => {
              ShowModalMenu(false);
            }}
          >
            X
          </h1>
        </div>
        <div className="Options">
          <Link to="/web/" className="href">
            HOME
          </Link>
          <div className="href">
            <ListItemButton
              sx={{ display: "flex", justifyContent: "center" }}
              onClick={() => setOpenCollapse(!openCollapse)}
            >
              OUR LINE CARD
              {openCollapse ? (
                <ExpandLess style={{ fontSize: "4rem" }} />
              ) : (
                <ExpandMore style={{ fontSize: "4rem" }} />
              )}
            </ListItemButton>
            <Collapse in={openCollapse}>
              <List component="div" disablePadding>
                <div className="FSCHome">
                  <div className="acordeonNsNs">
                    {GroupInfo ? (
                      GroupInfo.map((el, index) => {
                        const panelId = `panel${index}`;
                        return (
                          <Accordion
                            key={panelId}
                            expanded={expandedPanel === panelId}
                            onChange={handleChange(panelId)}
                          >
                            <AccordionSummary
                              aria-controls={panelId + "bh-content"}
                              id={panelId + "bh-header"}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>{el.FSG_TITLE}</div>
                              <div>
                                {expandedPanel === panelId ? (
                                  <ExpandLess style={{ fontSize: "4rem" }} />
                                ) : (
                                  <ExpandMore style={{ fontSize: "4rem" }} />
                                )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                                {el.FSC_GROUP.map((el2, index2) => (
                                  <ul key={"FSC " + index2}>
                                    <li
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        listStyle: "disc",
                                      }}
                                    >
                                      <span>
                                        <Link
                                          onClick={() => setModalOpen(false)}
                                          to={`/web/fsgs/${el.FSG}/fsc/${el2.FSC}/1`}
                                        >
                                          {el2.FSC_TITLE}
                                        </Link>
                                      </span>
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                    ) : (
                      <h1>Loading...</h1>
                    )}
                  </div>
                </div>
              </List>
            </Collapse>
          </div>
          <Link to="/web/fsg/1" className="href">
            NSN SEARCH
          </Link>
          <Link to="/web/electronics" className="href">
            ELECTRONIC COMPONENTS
          </Link>
          <Link to="/web/uploadrfq" className="href">
            UPLOAD RFQ
          </Link>
          <Link to="/web/contactUs" className="href">
            CONTACT US
          </Link>
          <Link to="/web/aboutus" className="href2">
            ABOUT US
          </Link>
        </div>
      </div>
    </>
  );
};

export default Menu;
