import { styled, Box, Grid, useMediaQuery} from "@mui/material";
import image5 from "../../assets/Banner/image5.webp";
import TypingAnimation from "./TypingAnimation";
import { CloudUpload } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import gsap from "gsap";
import AWN from "awesome-notifications";
import { ScrollToPlugin } from "gsap/all";
import { useEffect, useState } from "react";
import IframeSearchPart from "components/IframeElectronics/IframeSeatchPart";
gsap.registerPlugin(ScrollToPlugin);

const scrollToIframe = () => {
  gsap.to(window, {
    scrollTo: "#iframe-searchpart",
    duration: 1,
    delay: 1,
  });
};

const H1 = styled("h1")(({ theme }) => ({
  color: "white",
  fontWeight: 700,
  fontSize: "10rem",
  lineHeight: 1,
  [theme.breakpoints.down("md")]: {
    fontSize: "5rem",
    textAlign: "center",
    margin: "auto",
    width: "100%",
  },
}));

const AuxP = styled("p")(({ theme }) => ({
  color: "white",
  fontSize: "1.5rem",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.8rem",
    width: "100%",
    textAlign: "center",
  },
}));

const InputContainer = styled(Box)(({ theme }) => ({
  marginTop: "6rem",
  fontWeight: 400,
  fontSize: "1.2rem",
  display: "flex",
  justifyContent: "space-between",
  height: "3rem",
  width: "65%",
  "@media (max-width:1600px)": {
    // border: '1px solid red'
  },
  "@media (max-width:1025px)": {
    height: "auto",
    width: "90%",
    margin: "auto",
    marginTop: "5rem",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "9rem",
  },
}));

const InputPart = styled("input")(({ theme }) => ({
  border: "none",
  borderRadius: "8px",
  width: "70%",
  fontSize: "2rem",
  padding: "2rem",
  paddingLeft: "2rem",
  "@media(max-width:1025px)": {
    width: "100%",
    height: "4rem",
  },
}));

const ButtonSearch = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  border: "none",
  padding: "2rem",
  backgroundColor: "#D0402B",
  color: "white",
  width: "25%",
  fontSize: "2rem",
  borderRadius: "8px",
  transition: ".3s all",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#D0402B",
  },
  "@media(max-width:1024px)": {
    marginTop: "1rem",
    width: "50%",
    height: "4rem",
  },
}));

const ButtonUpload = styled(ButtonSearch)(({ theme }) => ({
  backgroundColor: "#3D3C3C",
  // width: '30%',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "90vh",
}));

export default function Banner({ part }) {
  let globalOptions = {
    positio: "bottom- right",
    maxNotifications: 10,
    animationDuration: 200,
    durations: 100,
  };
  let notifier = new AWN(globalOptions);

  const isMobile = useMediaQuery('(max-width: 600px)')

  const MainContainer = styled(Box)(({ theme }) => ({
    zIndex: -1,
    marginTop: "-1rem",
    backgroundImage: `url(${image5})`,
    backgroundPosition: 'bottom right',
    backgroundSize: "cover",
    minHeight: "800px",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#050728",
      minHeight: "800px",
      backgroundPositionX: "-400px",
      backgroundRepeat: "no-repeat",
    },
  }));

  const params = useParams();
  const [inputValue, setInputValue] = useState("");
  const [searchPart, setParSearch] = useState("");

  useEffect(() => {
    if (part) {
      setInputValue(part)
      setParSearch(part);
      scrollToIframe();
    }
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };
  const handleButtonSearch = (e) => {
    e.preventDefault();
    if (inputValue) {
      setParSearch(inputValue);
      scrollToIframe();
    } else {
      notifier.modal("<center>Please, input a part to search</center>");
    }
  };

  // let history = useHistory();
  const uploadrfq = () => {
    let path = "/web/uploadrfq";
    // history.push(path);
    window.location.href = path;
  };

  return (
    <>
      <MainContainer>
        <ContentContainer>
          <H1 id="main-content" tabIndex={0}>ELECTRONICS</H1>
          <TypingAnimation />
          <br />
          <br />
          <br />
          <InputContainer style={{height: '100%'}}>
            <label htmlFor="Search-Electronic-Component"></label>
            <InputPart
              placeholder="Search Electronic Component"
              value={inputValue}
              onChange={(e) => handleInputChange(e)}
              autoFocus={true}
              id="Search-Electronic-Component"
            />
            <ButtonSearch onClick={(e) => handleButtonSearch(e)}>
              SEARCH
            </ButtonSearch>
          </InputContainer>
          <InputContainer>
            <AuxP tabIndex="0">
              Have a list of items that needs quoting or do you have surplus
              inventory? Upload a list of the parts and we will get back to you
              with a quote.
            </AuxP>
            <ButtonUpload onClick={uploadrfq}>
              UPLOAD
              <CloudUpload style={{ fontSize: "2rem" }} />
            </ButtonUpload>
          </InputContainer>
        </ContentContainer>
      </MainContainer>
      <div id="iframe-searchpart">
        <IframeSearchPart searchPart={searchPart} />
      </div>
    </>
  );
}
