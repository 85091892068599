// import AltFooter from "components/AltFooter/AltFooter";
import Banner from "components/Banner/Banner";
import FooterBanner from "components/Banner/FooterBanner";
import IframeSearchPart from "./../../components/IframeElectronics/IframeSeatchPart";
import Menu from "./../../components/home/menu/menu";
import AltFooter from "./../../components/home/footer/footer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Electronics = () => {
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>PartMiner Electronics Components</title>
      </Helmet>
      <div>
        <div className="menuColor1">
          <Menu />
        </div>
        <div>
          <Banner part={params?.part} />
          {/* <div id="iframe-searchpart">
        <IframeSearchPart />
      </div> */}
          <AltFooter />
        </div>
      </div>
    </>
  );
};

export default Electronics;
