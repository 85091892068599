import React, { useState, useEffect } from 'react'
import "./fscLineCard.css";
// import arrowLeft from "./../../../assets/fscLineCard/arrowLeft.webp";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
// import { baseUrlContact } from "./../../httpBaseUrl.js";
import AWN from 'awesome-notifications';
import { styled } from '@mui/system';
import NsnBanner from 'components/Banner/NsnBanner';

const SectionOneContainer = styled('div')(({ theme }) => ({
    marginTop: '-110px',
    [theme.breakpoints.down('md')]: {
        marginTop: '-10px'
    }
}))

const ResultsContainer = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]:{
        marginTop:'10vh'
    }
}))

const FilterResults = () => {

    const [quantity, setQuantity] = useState('');
    const [rfqAdress, setRfqAdress] = useState('');
    const [rfqInput, setRfqInput] = useState('');
    const [InputSearchValue, setInputSearchValue] = useState('');
    const [data, setData] = useState([]);
    const history = useHistory();

    const handleQuantity = (event) => {
        const regex = /^[0-9]*$/;
        const valQuantity = regex.test(event.target.value) ? true : false;
        if (valQuantity === true) {
            setQuantity(event.target.value)
        }
    };


    const handleRfqInput = (event) => {
        setRfqInput(event.target.value)
    }

    const handleInputSearchValue = (event) => {
        setInputSearchValue(event.target.value)
    }

    const httpGet = (urlApi) => {
        return new Promise((responseAll, rejecteAll) => {
            let url = urlApi

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .then(response => {
                    responseAll(response)
                }).catch(err => rejecteAll(err));
        })
    }

    const GetGroups = () => {
        httpGet("https://api.part-miner.com/api/fsg/groups").then(res => {
            setData(res)
        })
    }

    useEffect(() => {
        GetGroups()
    }, [])

    var baseUrl = 'https://api.part-miner.com/'

    var url = baseUrl + `api/1/search?searchBy=${rfqInput}`

    function fetchTasks() {
        if (rfqInput.length === 13 || rfqInput.length === 9) {
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    const data1 = data[1]
                    const data5 = data[5]
                    const struc1 = [];
                    data1.map((e, index) => {
                        struc1.push({
                            ITEM_NAME: e.ITEM_NAME,
                            NSN: e.NSN,
                        })
                    })


                    data5.map((e, index) => {
                        struc1[0].PART_NUMBER = e.PART_NUMBER
                    })

                    localStorage.removeItem('data3')
                    localStorage.setItem('data3', JSON.stringify(struc1))
                    localStorage.setItem('rfqInfo', JSON.stringify({
                        rfqHomeInfo: rfqInput ? rfqInput : "",
                        rfqQuantity: "",
                    }))

                    history.push('/web/rfq')
                    // window.location.href = window.location.href;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(error => console.log("We are having issues with the server. Try again later"));

        } else {
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    localStorage.removeItem('data3')
                    localStorage.setItem('data3', JSON.stringify(data[1]))
                    history.push('/web/rfq')
                    // window.location.href = window.location.href;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(error => console.log("We are having issues with the server. Try again later"));

        }
    }

    // emailEl
    let globalOptions = {
        positio: 'bottom- right',
        maxNotifications: 3,
        animationDuration: 200,
        durations: 100,
    };
    let notifier = new AWN(globalOptions);

    const invalidExist = document.getElementsByClassName('invalid').length > 0;


    function redirectRfq(e) {

        if (!invalidExist && rfqInput && quantity) {
            e.preventDefault();
            setTimeout(() => {
                window.location.reload();
            }, 1500);
            //  fetchTasks();
            notifier.success('Request sent successfully', {
                durations: { success: 0 },
            })

            localStorage.removeItem('rfqInfo');
            localStorage.removeItem('tblRFQ');
            localStorage.setItem('rfqInfo', JSON.stringify({
                rfqHomeInfo: rfqInput,
                rfqQuantity: quantity,
            }))
            history.push('/web/rfq')
            fetchTasks()
            // }

        } else {
            // e.preventDefault();

            notifier.alert('Review Input Fields', { durations: { alert: 0 } });
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000);
        }
    }



    const handleRedirectToFSC = (fsg_code, fsc_code) => {
        /* http://localhost:3000/web/fsgs/10/fsc/1005/1 */
        history.push(`/web/fsgs/${fsg_code}/fsc/${fsc_code}/1`)
    }


    return (
        <>
            <SectionOneContainer className='section1'>
                <NsnBanner />
            </SectionOneContainer>
        </>
    )
}
export default FilterResults