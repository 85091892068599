import React from "react";
import classNames from "classnames";
import './styles.css';

function ModalHeader({ title, status }) {
  return (
    <div className="modal-header">
      {status === "error" && (
        <img
          className="modal-icon-status"
          src="https://cdn-icons-png.flaticon.com/512/272/272340.png"
          alt="modal_icon_status"
        />
      )}
      <p className="modal-title">{title}</p>
    </div>
  );
}

function ModalBody({ children }) {
  return <div className="modal-body">{children}</div>;
}

function ModalFooter({ children }) {
  return <div className="modal-footer">{children}</div>;
}

function Modal({ show, size = "medium", children }) {
  return (
    <div className={classNames("modal-component", [size], { show })}>
      <div className="modal-content">{children}</div>
    </div>
  );
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
