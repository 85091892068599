import React from 'react'
import { useHistory } from 'react-router-dom'
import "./../contacUpload.css";
import slider1 from "./../../../assets/rfq/3-1.webp";
import slider2 from "./../../../assets/rfq/4-1.webp";
import slider3 from "./../../../assets/rfq/7_720.webp";

const HeaderUpload = () => {
   // En tu función
    let history = useHistory();
    const rfq = () => {
        let path ="/web/rfq"
        history.push(path);
    }
    
    return (
        <div className='HeaderContact'>
            <div>
                <div className="sliderContactUs">
                    <ul>
                        <li>
                            <img src={slider1} alt="Tractor harvesting." />
                        </li>
                        <li>
                            <img src={slider2} alt="Robotic arm controlled by tablet." />
                        </li>
                        <li>
                            <img src={slider3} alt="Warship" />
                        </li>
                    </ul>
                </div>
            </div>
            <div className='headerContact'>
                <h1 tabIndex={0} id='main-content'>Contact PartMiner</h1>
                <h2 tabIndex={0}>This form is for general inquires only, if you need to request a quote</h2>
                <button onClick={rfq}>via online form here</button>
            </div>
        </div>
    )
}

export default HeaderUpload