import React, { useEffect, useState } from "react";
import "./../home.css";
import "./../../../assets/plugins/landing.min.css";
import { useHistory } from "react-router-dom";
import axios from "axios";

const SectionThree = () => {
  const [GroupInfo, setGroupInfo] = useState([]);

  function GetGroups() {
    axios
      .get("https://api.part-miner.com/api/fsg/groups")
      .then(function (res) {
        setGroupInfo(res.data);
      })
      .catch((error) =>
        console.log("We are having issues with the server. Try again later")
      );
  }

  let history = useHistory();
  function OurLineCardRedirect(param1, param2) {
    history.push("/web/fsgs/" + param1 + "/fsc/" + param2 + "/1");
  }

  useEffect(() => {
    GetGroups();
  }, []);

  return (
    <div className="ContentAllFSC">
      <div className="content3" style={{ padding: "20px" }}>
        <h2
          tabIndex="0"
          style={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "10px",
            fontSize: "36px",
          }}
        >
          About PartMiner
        </h2>
        <p style={{ fontSize: "16px", lineHeight: "1.5" }} tabIndex="0">
          PartMiner Industries is a global provider of NSN parts. Whether
          you’re a member of our military, a buyer for the aerospace or
          defense industry, a contractor or small business, the process is
          very simple:
        </p>
        <ul style={{ fontSize: "16px", lineHeight: "1.5" }}>
          <li tabIndex="0">Request a quote and complete the short form</li>
          <li tabIndex="0">Receive an immediate confirmation e-mail</li>
          <li tabIndex="0">
            Your sales representative will determine product availability
            within 24 to 48 hours
          </li>
        </ul>
        <p style={{ fontSize: "16px", lineHeight: "1.5" }} tabIndex="0">
          Our sales and procurement staff is made up of experienced, highly
          knowledgeable industry experts. We want to become your ‘go to’
          source for parts and materials. Think of us as an extension of your
          purchasing team.
          <br />
          <br />
          We hope you enjoy using our site and look forward to working with
          you.
        </p>
      </div>
    </div>
  );
};

export default SectionThree;
